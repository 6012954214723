<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض الفئة الفرعية</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                    <tr>
                      <th>الاسم باللغة الانجليزية</th>
                      <td>{{ subCategory.name }}</td>
                    </tr>                 
                    <tr>
                      <th>الاسم باللغة العربية</th>
                      <td>{{ subCategory.ar_name }}</td>
                    </tr>
                    <tr>
                      <th>الفئة الرئيسية</th>
                      <td>{{ subCategory.category.ar_name }}</td>
                    </tr>
                    <tr>
                      <th>الصورة</th>
                      <td>
                        <img
                            v-bind:src="subCategory.image_full_path"
                            style="height: 100px; width: 100px; margin-right: 15px"
                        />
                      </td>
                    </tr>
                </table>
              </div>
            </div>
            <CButton color="warning" @click="goBack">رجوع</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "EditSubCategory",
  data() {
    return {
      subCategory: [],
      image: "",
      Categories: [],
      selected: "",
    };
  },
  created() {
    this.$http
      .get(
        `${process.env.VUE_APP_URL}sub-categories/${this.$route.params.id}`
      )
      .then((response) => {
        this.subCategory = response.data.data;
        this.selected = response.data.data.category_id;
      });
  },
  methods: {
    goBack() {
      this.categoriesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/sub-categories" });
    },
  },
};
</script>
<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
